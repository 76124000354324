<template>
  <b-card>
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="handleSubmit"
      >
        <b-row>
          <b-card
            title="Package Details"
            class="mb-0 w-100"
          >
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Package Name"
                  label-for="package-name"
                >
                  <b-form-input
                    id="package-name"
                    v-model="form.name"
                    placeholder="Enter Package Name"
                    disabled
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Interval Value"
                  label-for="interval-value"
                >
                  <b-form-input
                    id="interval-value"
                    v-model="form.interval_count"
                    placeholder="Enter Interval Value"
                    disabled
                    pattern="[0-9]+"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Interval Type"
                  label-for="interval"
                >
                  <v-select
                    v-model="form.interval"
                    :options="intervalOptions"
                    :reduce="option => option.key"
                    label="name"
                    placeholder="Select Interval Type"
                    disabled
                    deselect-from-dropdown
                  >
                    <template
                      v-slot:option="option"
                      class="text-truncate"
                    >
                      {{ option.name }}
                    </template>
                    <template #search="{attributes}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="!form.interval"
                        v-bind="attributes"
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Grace Days"
                  label-for="grace-days"
                >
                  <b-form-input
                    id="grace-days"
                    v-model="form.grace_days"
                    placeholder="Enter Grace Period"
                    disabled
                    pattern="[0-9]+"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    disabled
                    placeholder="Enter Subject"
                    rows="6"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label-for="is_active"
                >
                  <b-form-checkbox
                    id="is_active"
                    v-model="form.is_active"
                    :value="true"
                    :unchecked-value="false"
                    disabled
                    style="margin-top:28px;"
                  >
                    Is Active
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2 mb-2">
            The following fields specify the billing rates for orders applicable to billing
          </div>
        </b-row>
        <b-row
          v-for="(rate, index) in form.rates"
          :key="index"
        >
          <b-col
            md="3"
            style="margin-left:6px;"
          >
            <b-form-group
              label="From Count"
              label-for="from-count"
            >
              <b-form-input
                id="from-count"
                v-model="rate['from_count']"
                disabled
                placeholder="Enter From Count"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="To Count"
              label-for="to-count"
            >
              <b-form-input
                id="to-count"
                v-model="rate['to_count']"
                disabled
                placeholder="Enter To Count"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="rate['price']"
                placeholder="Enter Price"
                disabled
                pattern="[0-9]+"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BCard, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BCard,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      id: null,
      form: {
        data: {},
        rates: [
          {
            from_count: '',
            to_count: '',
            price: '',
          },
        ],
      },
      intervalOptions: [
        {
          key: 'days',
          name: 'Days',
        },
        {
          key: 'months',
          name: 'Months',
        },
        {
          key: 'years',
          name: 'Years',
        },
      ],
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getPackageDetails()
  },
  methods: {
    async getPackageDetails() {
      this.loading = true
      try {
        const { data } = await SubscriptionRepository.getSubscriptionPackageById(this.id)
        this.form = data
        this.form.rates = this.form.package_rates
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
